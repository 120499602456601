import React from "react";
import styles from "./ToDoList.module.css";
import { FaTruckMedical } from "react-icons/fa6";
import { FaPassport } from "react-icons/fa";
import { RiPoliceCarFill } from "react-icons/ri";
import { IoManSharp } from "react-icons/io5";
import CustomBtn from "../../CustomBtn/CustomBtn";

export default function ToDoList(props) {
  return (
    <ul className={styles.todolist}>
      <li>
        <div className={styles.list_item_wrapper}>
          <div className={styles.icon_wrapper}>
            <FaTruckMedical className={styles.icon + " " + styles.anim_med} />
          </div>
          <h4 className={styles.list_item_text}>
            Необходимо вызвать скорую помощь
          </h4>
        </div>
      </li>

      <li>
        <div className={styles.list_item_wrapper}>
          <div className={styles.icon_wrapper}>
            <RiPoliceCarFill
              className={styles.icon + " " + styles.anim_police}
            />
          </div>
          <h4 className={styles.list_item_text}>Необходимо вызвать милицию</h4>
        </div>
      </li>

      <li>
        <div className={styles.list_item_wrapper}>
          <div className={styles.icon_wrapper}>
            <FaPassport className={styles.icon + " " + styles.anim_passport} />
          </div>
          <h4 className={styles.list_item_text}>Приготовить ваш паспорт</h4>
        </div>
      </li>

      <li>
        <div className={styles.list_item_wrapper}>
          <div className={styles.icon_wrapper}>
            <FaPassport className={styles.icon + " " + styles.anim_passport} />
          </div>
          <h4 className={styles.list_item_text}>
            Приготовить паспорт умершего
          </h4>
        </div>
      </li>

      <li>
        <div className={styles.list_item_wrapper}>
          <div className={styles.icon_wrapper}>
            <IoManSharp className={styles.icon + " " + styles.anim_human} />
          </div>
          <h4 className={styles.list_item_text}>Вызвать ритуального агента</h4>
        </div>
      </li>

      <div className={styles.btn_wrapper + " mt-lg-3 "}>
        <CustomBtn href="/#WhyChooseUs" title="Почему выбирают нас?" />
      </div>
    </ul>
  );
}
