import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import img from "../DieWTD/HumanDIE.webp";
import ToDoList from "./ToDoList/ToDoList";

export default function DieWTD() {
  return (
    <Container className="pt-5">
      <Row className="justify-content-center">
        <Col xs="12">
          <div className="h1_wrapper">
            <h1 className=" h1-separator">Если умер человек</h1>
          </div>
        </Col>
      </Row>
      <Row className="pt-xl-5 mt-2 align-items-center justify-content-center">
        <Col xs="12" lg="6" xl="4" className="mx-xl-4 align-items-center">
          <Image src={img} fluid />
        </Col>
        <Col xs="12" lg="6" xl="4" className="mt-4 mt-lg-0">
          <ToDoList />
        </Col>
      </Row>
    </Container>
  );
}
