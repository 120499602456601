import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../Menu/logo.png";
import styles from "../Menu/Menu.module.css";
import { NavLink } from "react-router-dom";

export default function Menu() {
  const [show, setShow] = useState(false);
  const SH_Dropdown = () => {
    setShow(!show);
  };
  return (
    <Navbar
      expand="sm"
      fixed="top"
      expanded={show}
      className={styles.navbar}
      data-bs-theme="dark"
    >
      <Container>
        <Navbar.Brand href="/#Home" className="d-flex align-items-center">
          <img
            alt=""
            src={logo}
            width="70"
            height="40"
            className="d-inline-block align-center"
          />
          <NavLink to="tel:80291262546" className={styles.logo_title + " " + styles.set_my_color + " m-0"}>
            +375 (29) 126-25-46
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={SH_Dropdown}
          className={
            styles.set_my_color +
            " " +
            styles.navbar_toggler_icon +
            "  " +
            styles.toggle
          }
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={"d-md-flex justify-content-end"}
        >
          <Nav>
            <div className="Vcentralizer">
              <NavLink
                className={styles.set_my_color + " " + styles.link}
                onClick={SH_Dropdown}
                to="/#Home"
              >
                Главная
              </NavLink>
            </div>

            <div className="Vcentralizer">
              <NavLink
                className={styles.set_my_color + " " + styles.link}
                onClick={SH_Dropdown}
                to="/#Services"
              >
                Услуги
              </NavLink>
            </div>

            <div className="Vcentralizer">
            <NavLink
                className={styles.set_my_color + " " + styles.link}
                onClick={SH_Dropdown}
                to="/Products/Coffins#Products"
              >
                Продукция
              </NavLink>
            </div>
            
            <div className="Vcentralizer">
              <NavLink
                className={styles.set_my_color + " " + styles.link}
                onClick={SH_Dropdown}
                to="/#WhyChooseUs"
              >
                О нас
              </NavLink>
            </div>

            <div className="Vcentralizer">
              <NavLink
                className={styles.set_my_color + " " + styles.link}
                onClick={SH_Dropdown}
                to="#Contacts"
              >
                Контакты
              </NavLink>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
