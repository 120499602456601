import React from "react";
import styles from "../Services/Services.module.css";
import { Container, Row, Col } from "react-bootstrap";
import agent from "../Services/agent.webp";
import documents from "../Services/documents.webp";
import balzamirovanie from "../Services/balzamirovanie.webp";
import transporting from "../Services/transporting.webp";
import panihida from "../Services/panihida.webp";
import products from "../Products/ProductsInformation/ProductsInformation.webp";
import kremirovanie from "../Services/kremirovanie.webp";
import pominki from "../Services/pominki.webp";
import zal from "../Services/zal.webp";
import Card from "../Card/Card";
import CustomBtn from "./../CustomBtn/CustomBtn";

export default function Services() {
  const service_list = [
    {
      id: 1,
      src: agent,
      header_text: "Выезд ритуального агента",
      description:
        "Перед вызовом ритуального агента проводится первичная консультация по телефону. ",
      price: "Бесплатно",
    },
    {
      id: 2,
      src: documents,
      header_text: "Оформление и сбор документов",
      description:
        "Сбор обязательных документов для организации похорон. Посещение всех инстанций для оформления бумаг в соответствии с требованиями законодательства Республики Беларусь. ",
      price: "Бесплатно",
    },
    {
      id: 3,
      src: balzamirovanie,
      header_text: "Подготовка усопшего к похоронам",
      description:
        "Сотрудники морга подготавливают тело, чтобы придать усопшему «свежий» вид (обмывание, дезинфекция, тампонирование, одевание).",
      price: "от 100 BYN",
    },
    {
      id: 4,
      src: products,
      header_text: "Подбор ритуальных услуг и товаров",
      description:
        "Проведение консультативной помощи для подбора ритуальных услуг и товаров с учётом планируемого бюджета и с индивидуальным подходом к каждому заказчику.",
      price: "от 150 BYN",
    },
    {
      id: 5,
      src: transporting,
      header_text: "Транспортировка тела и близких",
      description:
        "Транспортировка тела усопшего на специализированном транспорте с адреса в морг и на кладбище. Перевозка близких на кладбище и место поминок. ",
      price: "от 100 BYN",
    },
    {
      id: 6,
      src: panihida,
      header_text: "Панихида и ритуальный обряд",
      description:
        "Аренда и оформление ритуального зала (портрет покойного, лампады, цветы, венки, атрибуты, музыкальное сопровождение и прочее). Организация гражданской панихиды и отпевания.",
      price: "от 100 BYN",
    },
    {
      id: 7,
      src: kremirovanie,
      header_text: "Захоронение или кремация",
      description:
        "Осмотр места захоронения и оформление участка. Решение сложностей по захоронению. Захоронение тела с гробом в землю или кремация с последующим захоронением урны с прахом.",
      price: "от 300 BYN",
    },
    {
      id: 8,
      src: pominki,
      header_text: "Организация поминок",
      description:
        "Организация поминальной трапезы в отдельном зале кафе, ресторана либо в банкетном зале. Заказ готовых блюд поминального меню с доставкой на дом или на кладбище.",
      price: "от 200 BYN",
    },
    {
      id: 9,
      src: zal,
      header_text: "Предоставление траурных залов",
      description:
        "Наша компания может предоставить собственные траурные залы для проведения ритуальных церемоний и прощания с усопшим.",
      price: "от 60 BYN",
    },
  ];

  return (
    <Container id='Services' className="pt-10">
      <Row className="justify-content-center">
        <Col xs="12">
          <div className="h1_wrapper">
            <h1 className=" h1-separator">Спектр услуг</h1>
          </div>
        </Col>
      </Row>
      <Row className={"justify-content-center mt-2"}>
        <div className={styles.services_wrapper}>
          {service_list.map((value, key) => (
            <div key={value.id} className={styles.item_wrapper}>
              <Card                
                id={value.id}
                src={value.src}
                header_text={value.header_text}
                description={value.description}
                price={value.price}
              />
            </div>
          ))}
        </div>
      </Row>
      <Row className="mt-3 mt-xl-5 pb-0 d-flex">
        <Col xs="12" className="justify-content-center">
          <div className="centralizer">
            <CustomBtn title="Заказать услуги" href="#Contacts" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
