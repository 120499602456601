import React from "react";
import styles from "../Contacts/Contacts.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { FaViber, FaPhoneVolume } from "react-icons/fa6";

export default function Contacts() {
  return (
    <Container id="Contacts" className="pt-10 pb-0">
      <Row className="justify-content-center">
        <Col xs="12">
          <div className="h1_wrapper">
            <h1 className=" h1-separator">Контакты </h1>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mt-1">
        <Col xs="12" lg="6">
          <Container fluid>
            <Row>
              <Col sm="4">
                <div className={styles.contact_wrapper}>
                  <div className={styles.contact_card}>
                    <div className={styles.icon_wrappper}>
                      <FaPhoneVolume
                        size={100}
                        className={styles.icon + " " + styles.phone_icon}
                      />
                    </div>
                    <div className={styles.contact_text_wrapper}>
                      <p className={"text-center"}>А1</p>
                      <a
                        href="tel:80291262546"
                        className={styles.links + " " + styles.anim_phone}
                      >
                        +375 (29) 126-25-46
                      </a>
                      <p>Макаревич Вячеслав</p>
                      <p className="text-center">Васильевич</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="4">
                <div className={styles.contact_wrapper}>
                  <div className={styles.contact_card}>
                    <div className={styles.icon_wrappper}>
                      <FaViber
                        size={100}
                        className={styles.icon + " " + styles.viber_icon}
                      />
                    </div>
                    <div className={styles.contact_text_wrapper}>
                      <p className={"text-center"}>Viber</p>
                      <a
                        href="viber://add?number=375291262546"
                        className={styles.links + " " + styles.anim_viber}
                      >
                        +375 (29) 126-25-46
                      </a>
                      <p>Макаревич Вячеслав</p>
                      <p className="text-center">Васильевич</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col sm="4">
                <div className={styles.contact_wrapper}>
                  <div className={styles.contact_card}>
                    <div className={styles.icon_wrappper}>
                      <FaPhoneVolume
                        size={100}
                        className={styles.icon + " " + styles.phone_icon}
                      />
                    </div>
                    <div className={styles.contact_text_wrapper}>
                      <p className={"text-center"}>МТС</p>
                      <a
                        href="tel:80336805060"
                        className={styles.links + " " + styles.anim_phone}
                      >
                        +375 (33) 680-50-60
                      </a>
                      <p>Макаревич Вячеслав</p>
                      <p className="text-center">Васильевич</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
