import React, { useState } from "react";
import {
  Container,
  Navbar,
  Row,
  Col,
  Nav,
  Modal,
} from "react-bootstrap";
import styles from "../Footer/Footer.module.css";
import logo from "../Menu/logo.png";
import CustomBtn from "../CustomBtn/CustomBtn";

export default function Footer() {
  const [show, setShow] = useState(false);
  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);
  return (
    <Container className={styles.container + " pt-10 pb-1"}>
      <Row>
        <Col xs="12" className="p-0">
          <Navbar expand="sm" bg="dark" className={styles.navbar}>
            <Container className="pt-3 pb-2">
              <Navbar.Brand href="/#Home" className="d-flex align-items-center">
                <img
                  alt=""
                  src={logo}
                  width="70"
                  height="40"
                  className="d-inline-block align-center"
                />
                <p
                  className={
                    styles.logo_title + " " + styles.set_my_color + " m-0"
                  }
                >
                  Ritual Agent
                </p>
              </Navbar.Brand>
              <Nav className="me-auto justify-content-center">
                <Nav.Link className={styles.set_my_color + ' ' + styles.unp}>
                  УНП: <span>791025884</span>
                </Nav.Link>
              </Nav>
              <Navbar.Text>
                <a
                  className={styles.set_my_color + " " + styles.politic}
                  onClick={showModal}
                >
                  Политика конфиденциальности
                </a>
              </Navbar.Text>
            </Container>
          </Navbar>
        </Col>
      </Row>
      <Row>
        <Modal show={show} onHide={hideModal} className={styles.modal}>
          <Modal.Header>
            <Modal.Title>Политика конфиденциальности</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Данная Политика конфиденциальности применима к данному Сайту. После
            просмотра данного Сайта обязательно прочитайте текст, содержащий
            политику конфиденциальности используемого сайта. В случае несогласия
            с данной Политикой конфиденциальности прекратите использование
            данного Сайта. Заполнив любую из форм и используя данный Сайт, Вы
            тем самым выражаете согласие с условиями изложенной ниже Политики
            конфиденциальности. Сайт охраняет конфиденциальность посетителей
            сайта. Персональная информация Для того чтобы оказывать вам услуги,
            отвечать на вопросы, выполнять ваши пожелания и требования требуется
            такая информация, как ваше имя и номер телефона. САЙТ может
            использовать указанную информацию для ответов на запросы, а также
            для связи с Вами по телефону с целью предоставления информации о
            предлагаемых САЙТ услугах и рекламных кампаниях. При поступлении от
            вас обращения в виде отправки любой заполненной на сайте формы САЙТ
            может потребоваться связаться с Вами для получения дополнительной
            информации, необходимой для вашего обслуживания и ответа на
            интересующие вопросы. САЙТ обязуется не передавать данную информацию
            третьим лицам без Вашего разрешения, за исключением информации,
            необходимой для выполнения вашего обслуживания. Со своей стороны, Вы
            предоставляете САЙТ право использовать любую сообщѐнную Вами
            информацию для выполнения указанных выше действий.
          </Modal.Body>
          <Modal.Footer>
            <CustomBtn onClick={hideModal} title="Закрыть" />
          </Modal.Footer>
        </Modal>
      </Row>
    </Container>
  );
}
