import React from "react";
import styles from "../FixedContacts/FixedContacts.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneVolume } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

export default function FixedContacts() {
  return (
    <Container className={styles.container}>
      <Row>
        <Col xs="3" className="offset-9">
          <NavLink
            to="#Contacts"
            className={styles.link}
          >
            <FaPhoneVolume
              size={25}
              className={styles.icon + " " + styles.anim_phone}
            />
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
}
