import Cross1 from '../CrossesList/Cross1.webp';
import Cross2 from '../CrossesList/Cross2.webp';
import Cross3 from '../CrossesList/Cross3.webp';

const CrossesList = [
  {
    id: 1,
    src: Cross1,
    title: "Model 1",
    text: "Массив дерева, покрытый лаком",
    price: "от 30 BYN",
  },
  {
    id: 2,
    src: Cross2,
    title: "Model 2",
    text: "Массив дерева, покрытый лаком",
    price: "от 30 BYN",
  },
  {
    id: 3,
    src: Cross3,
    title: "Model 3",
    text: "Массив дерева, покрытый лаком",
    price: "от 30 BYN",
  },
];

export default CrossesList;

