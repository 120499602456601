import React from "react";
import styles from "../ProductCard/ProductCard.module.css";
import { Card } from "react-bootstrap";
import CustomBtn from "../../CustomBtn/CustomBtn";

export default function ProductCard(props) {
  return (
    <Card className={styles.container}>
      <Card.Img variant="top" src={props.src} />
      <Card.Body>
        <Card.Title className={styles.title}>{props.title}</Card.Title>
        <Card.Text className={styles.text}>{props.text}</Card.Text>
        <Card.Title className={styles.price}>
          {props.price} <span></span>
        </Card.Title>
        <div className="centralizer">
          <CustomBtn className={styles.btn} href="#Contacts" title="Заказать" />
        </div>
      </Card.Body>
    </Card>
  );
}
