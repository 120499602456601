import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import DieWTD from "./../DieWTD/DieWTD";
import Services from "../Services/Services";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";
import Products from "../Products/ProductsInformation/ProductsInformation";
import Home from "../Home/Home";
import { useLocation } from "react-router-dom";

export default function MainPage() {
  const location = useLocation().hash;
  useEffect(() => {
    if (location !== null && location !== "") {
      document.querySelector(location).scrollIntoView();
    }
  });
  return (
    <Container fluid>
      <Row>
        <Home />
      </Row>
      <Row className="mt-5">
        <DieWTD />
      </Row>
      <Row>
        <Services />
      </Row>
      <Row className="mt-0">
        <WhyChooseUs />
      </Row>
      <Row className="mt-0">
        <Products />
      </Row>
    </Container>
  );
}
