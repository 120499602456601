import React from "react";
import { Container, Row } from "react-bootstrap";
import { Routes, Route} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css";
import Menu from "./Components/Menu/Menu";
import Contacts from "./Components/Contacts/Contacts";
import Footer from "./Components/Footer/Footer";
import MainPage from "./Components/MainPage/MainPage";
import Products from "./Components/Products/Products/Products";
import FixedContacts from "./Components/FixedContacts/FixedContacts";

function App() {
  return (
    <Container fluid >
        <Row>
          <Menu />
          <FixedContacts/>
        </Row>
        <Row>
          <Routes>
            <Route path='*' element={<MainPage />}/>
            <Route path='/Products/*' element={<Products/>}/>
          </Routes>
        </Row>
        <Row className="mt-0">
          <Contacts />
        </Row>
        <Row className="mt-0">
          <Footer />
        </Row>
      </Container>
  );
}

export default App;
