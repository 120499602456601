import React from "react";
import styles from "../ProductsInformation/ProductsInformation.module.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaHandPointer } from "react-icons/fa";
import CustomBtn from "../../CustomBtn/CustomBtn";
import productsImg from "../ProductsInformation/ProductsInformation.webp";

export default function ProductsInformation() {
  const product_list = [
    "Качественная продукция",
    "Помощь в выборе",
    "Доставка в дом",
    "Низкие цены",
    "Гробы и Кресты",
    "Венки и Корзины",
  ];
  return (
    <Container className="pt-10 pb-0">
      <Row className="justify-content-center">
        <Col xs="12">
          <div className="h1_wrapper">
            <h1 className=" h1-separator">Наша продукция</h1>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mt-3">
        <Col xs="12" lg="5" xl="4">
          <Image src={productsImg} fluid itemProp="contentUrl"/>
        </Col>
        <Col xs="12" lg="5" xl="4" className="mt-4">
          <ul className={styles.list}>
            {product_list.map((val, key) => (
              <li key={key}>
                <div className={styles.list_item_wrapper}>
                  <div className={styles.icon_wrapper}>
                    <FaHandPointer
                      className={styles.icon + " " + styles.anim_med}
                    />
                  </div>
                  <h6 className={styles.list_item_text}>{val}</h6>
                </div>
              </li>
            ))}
            <li>
              <div className="mt-4">
                <CustomBtn title="Просмотреть продукцию" href="/Products/Coffins#Products" />
              </div>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
