import React from "react";
import styles from "../WhyChooseUs/WhyChooseUs.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaHandPointer } from "react-icons/fa";

export default function WhyChooseUs() {
  const list1 = [
    "Работаем круглосуточно",
    "Бесплатная консультация агента",
    "Приезжаем и оказываем помощь даже ночью!",
    "Низкие цены",
    "Осуществляем доставку ритуальной продукции",
    "Оперативность и ответственность",
  ];
  const list2 = [
    "Презентабельные машины",
    "Профессиональная консультация",
    "Быстро решаем любые вопросы",
    "Официальный договор",
    "Возможен безналичный расчет",
    "Качественное ритуальное обслуживание",
  ];

  return (
    <Container id="WhyChooseUs" className="pt-10 pb-3">
      <Row className="justify-content-center">
        <Col xs="12">
          <div className="h1_wrapper">
            <h1 className=" h1-separator">Почему выбирают нас?</h1>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center offset-xl-1 mt-3">
        <Col xs="12" lg="5" xl="4">
          <ul className={styles.list}>
            {list1.map((val, key) => (
              <li key={key}>
                <div className={styles.list_item_wrapper}>
                  <div className={styles.icon_wrapper}>
                    <FaHandPointer
                      className={styles.icon + " " + styles.anim_med}
                    />
                  </div>
                  <h6 className={styles.list_item_text}>{val}</h6>
                </div>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs="12" lg="5" xl="4">
          <ul className={styles.list}>
            {list2.map((val, key) => (
              <li key={key}>
                <div className={styles.list_item_wrapper}>
                  <div className={styles.icon_wrapper}>
                    <FaHandPointer
                      className={styles.icon + " " + styles.anim_med}
                    />
                  </div>
                  <h6 className={styles.list_item_text}>{val}</h6>
                </div>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
