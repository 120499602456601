import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "../Products/Products.module.css";
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import CoffinList from "../CoffinList/CoffinList";
import CrossesList from "../CrossesList/CrossesList";
import WreathsList from "../WreathsList/WreathsList";
import ProductList from "../ProductList/ProductList";

export default function Products() {
  const location = useLocation().hash;
  useEffect(() => {
    if (location !== null && location !== "") {
      document.querySelector(location).scrollIntoView();
    }
  });
  return (
    <Container id="Products" className="mt-product-list pt-10">
      <Row xl={6} className="justify-content-center">
        <Col xs="4">
          <div className="h1_wrapper">
            <NavLink
              className={({ isActive }) =>
                (isActive ? styles.active_link : "") +
                " " +
                styles.set_my_color +
                " h1-separator "
              }
              to="Coffins#Products"
            >
              Гробы
            </NavLink>
          </div>
        </Col>
        <Col xs="4">
          <div className="h1_wrapper">
            <NavLink
              className={({ isActive }) =>
                (isActive ? styles.active_link : "") +
                " " +
                styles.set_my_color +
                " h1-separator "
              }
              to="Crosses#Products"
            >
              Кресты
            </NavLink>
          </div>
        </Col>
        <Col xs="4">
          <div className="h1_wrapper">
            <NavLink
              className={({ isActive }) =>
                (isActive ? styles.active_link : "") +
                " " +
                styles.set_my_color +
                " h1-separator "
              }
              to="Wreaths#Products"
            >
              Венки
            </NavLink>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" md="9" lg="10" xl="8">
          <Routes>
            <Route
              path="Coffins"
              element={<ProductList title="Гробы" items={CoffinList} />}
            />
            <Route
              path="Crosses"
              element={<ProductList title="Кресты" items={CrossesList} />}
            />
            <Route
              path="Wreaths"
              element={<ProductList title="Венки" items={WreathsList} />}
            />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}
