import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProductCard from "../ProductCard/ProductCard";

export default function ProductList(props) {
  return (
    <Container>
      <Row className="justify-content-center">
        {props.items.map((value) => (
          <Col
            xs="12"
            md="6"
            xl="4"
            xxl="3"
            className="mt-4 d-flex justify-content-center"
          >
            <ProductCard
              key={value.id}
              src={value.src}
              title={value.title}
              text={value.text}
              price={value.price}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
