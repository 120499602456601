import React from 'react';
import styles from './Card.module.css'
import { Container, Row, Image } from 'react-bootstrap';

export default function Card(props) {
  return (
    <Container className={styles.container + ' ' + props.className}>
        <Row>
            <Image src={props.src} fluid/>
        </Row>
        <Row>
            <p className={styles.header}>{props.header_text}</p>
            <p className={styles.description}>{props.description}</p>
            <p className={styles.price}>{props.price}</p>
        </Row>
    </Container>
  )
}
