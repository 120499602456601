import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import CustomBtn from "../CustomBtn/CustomBtn";
import styles from "../Home/Home.module.css";

export default function Home() {
  return (
    <Container id="Home" fluid>
      <Row>
        <Col sm="12" className="mt-5 p-0">
          <div className={styles.BG + " d-flex justify-content-center"}>
            <Container className="pt-2 pb-5">
              <Row>
                <Col xs="12" lg="10" xl="9">
                  <div className={styles.text_wrapper + " "}>
                    <h1 className="text-start">
                      Ритуальные услуги в Могилеве и области
                      <br />
                      <span className={styles.phone}>+375 (29) 126-25-46</span>
                      <div className={styles.pouring}>любой бюджет!</div>
                    </h1>
                    <p className=" pb-3 pb-lg-5 text-lg-start">
                      Организация похорон: услуги катафалка, копка могилы,
                      подготовка усопшего, поминальные обеды, услуги священника.
                      <br />
                      <br />
                      Принадлежности: гробы, венки и кресты собственного
                      производства
                      <br />
                      <span className={styles.pouring + " " + styles.free}>
                        {" самые низкие цены! "}
                      </span>
                      Выезд ритуального агента, оформление документов и доставка
                      принадлежностей
                      <span className={styles.pouring + " " + styles.free}>
                        {" абсолютно бесплатно! "}
                      </span>
                    </p>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="d-flex d-lg-block justify-content-center">
                    <CustomBtn
                      className="mt-xs-4"
                      href="#Contacts"
                      title="Связаться с нами"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
