import wreath1 from './wreath1.webp';
import wreath2 from './wreath2.webp';
import wreath3 from './wreath3.webp';
import wreath4 from './wreath4.webp';
import wreath5 from './wreath5.webp';

const WreathsList = [
  {
    id: 1,
    src: wreath1,
    title: "Model 1",
    text: "Ритуальный венок, с лентой",
    price: "от 40 BYN",
  },
  {
    id: 2,
    src: wreath2,
    title: "Model 2",
    text: "Ритуальный венок, с лентой",
    price: "от 40 BYN",
  },
  {
    id: 3,
    src: wreath3,
    title: "Model 3",
    text: "Ритуальный венок, с лентой",
    price: "от 40 BYN",
  },
  {
    id: 4,
    src: wreath4,
    title: "Model 4",
    text: "Ритуальный венок, с лентой",
    price: "от 40 BYN",
  },
  {
    id: 5,
    src: wreath5,
    title: "Model 5",
    text: "Ритуальный венок, с лентой",
    price: "от 40 BYN",
  },
];

export default WreathsList;

