import Lak1 from './Lak1.webp';
import Lak2 from './Lak2.webp';
import Lak3 from './Lak3.webp';
import Lak4 from './Lak4.webp';
import Tkan1 from './tkan1.webp';
import Tkan2 from './tkan2.webp';
import Tkan3 from './tkan3.webp';


const CoffinList = [
  {
    id: 1,
    src: Lak1,
    title: "Model 1",
    text: "Массив дерева, покрытый лаком, полированный",
    price: "300 BYN",
  },
  {
    id: 2,
    src: Lak2,
    title: "Model 2",
    text: "Массив дерева, покрытый лаком, полированный",
    price: "300 BYN",
  },
  {
    id: 3,
    src: Lak3,
    title: "Model 3",
    text: "Массив дерева, покрытый лаком, полированный",
    price: "300 BYN",
  },
  {
    id: 4,
    src: Lak4,
    title: "Model 4",
    text: "Массив дерева, покрытый лаком, полированный",
    price: "300 BYN",
  },
  {
    id: 5,
    src: Tkan1,
    title: "Model 5",
    text: "Массив дерева, с механизмом, обитый тканью",
    price: "от 80 BYN",
  },
  {
    id: 6,
    src: Tkan2,
    title: "Model 6",
    text: "Массив дерева, с механизмом, обитый тканью",
    price: "от 80 BYN",
  },
  {
    id: 7,
    src: Tkan3,
    title: "Model 7",
    text: "Массив дерева, с механизмом, обитый тканью",
    price: "от 80 BYN",
  },
];

export default CoffinList;

